import React, { useState } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Swal from 'sweetalert2';
import axios from 'axios';
import { CheckCustomerisFarmerornot, AddGetSalesbyFPO } from '../../Api_url';

const AddSales = ({ openAddDialog, setOpenAddDialog }) => {
    const [salesData, setSalesData] = useState([
        {
            name: '',
            mobile_no: '',
            address: '',
            product_id: '',
            variant_id: '',
            quantity: '',
        },
    ]);

    const [isMobileValid, setIsMobileValid] = useState(false);  // To store mobile number validity
    const [farmerAssociated, setFarmerAssociated] = useState(false);  // To store farmer associated status

    // Add a new row to the sales data
    const handleAddRow = () => {
        setSalesData([
            ...salesData,
            {
                name: '',
                mobile_no: '',
                address: '',
                product_id: '',
                variant_id: '',
                quantity: '',
            },
        ]);
    };

    // Delete a specific row from sales data
    const handleDeleteRow = (index) => {
        const updatedSalesData = salesData.filter((_, i) => i !== index);
        setSalesData(updatedSalesData);
    };


    // Handle submit
    const handleSubmit = async () => {
        // Validate if any row is incomplete
        for (let row of salesData) {
            if (
                !row.name ||
                !row.mobile_no ||
                !row.address ||
                !row.product_id ||
                !row.variant_id ||
                !row.quantity
            ) {
                Swal.fire({
                    icon: 'warning',
                    title: 'All fields are required',
                    text: 'Please fill all the fields.',
                    confirmButtonText: 'OK',
                    didOpen: () => {
                        const swalElement = document.querySelector('.swal2-container');
                        if (swalElement) {
                            swalElement.style.zIndex = 1500;
                        }
                    }
                });
                return;
            }
        }

        const token = localStorage.getItem('access_token');
        const apiKey = 'buyer';

        // Prepare sales data for API request
        const requestData = salesData.map((row) => ({
            name: row.name,
            mobile_no: row.mobile_no,
            address: row.address,
            sales_data: [
                {
                    product_id: parseInt(row.product_id),
                    variant_id: parseInt(row.variant_id),
                    quantity: parseInt(row.quantity),
                },
            ],
        }));

        try {
            const response = await axios.post(AddGetSalesbyFPO, requestData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'api-key': apiKey,
                },
            });

            if (response?.status === 200) {
                setOpenAddDialog(false);
                Swal.fire('Success', 'Sales added successfully!', 'success');
            } else {
                setOpenAddDialog(false);
                Swal.fire('Error', response?.data?.message || 'Something went wrong', 'error');
            }
        } catch (error) {
            setOpenAddDialog(false);
            Swal.fire('Error', error?.response?.data?.message || 'Something went wrong', 'error');
        }
    };

    const handleVerifyMobileNumber = async (index) => {
        const mobileNo = salesData[index].mobile_no;

        if (!mobileNo) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a mobile number',
                didOpen: () => {
                    const swalElement = document.querySelector('.swal2-container');
                    if (swalElement) {
                        swalElement.style.zIndex = 1500;
                    }
                }
            });
            return;
        }

        const token = localStorage.getItem('access_token'); // Get the token from localStorage

        if (!token) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No access token found',
                didOpen: () => {
                    const swalElement = document.querySelector('.swal2-container');
                    if (swalElement) {
                        swalElement.style.zIndex = 1500;
                    }
                }
            });
            return;
        }

        try {
            const response = await axios.get(CheckCustomerisFarmerornot, {
                params: { mobile: mobileNo },
                headers: {
                    'Authorization': `Bearer ${token}`, // Add token to request headers
                }
            });

            if (response?.data?.associated) {
                setFarmerAssociated(true); // Show discount field
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Mobile number is associated with a farmer',
                    didOpen: () => {
                        const swalElement = document.querySelector('.swal2-container');
                        if (swalElement) {
                            swalElement.style.zIndex = 1500;
                        }
                    }
                });
            } else {
                setFarmerAssociated(false); // Do not show discount field
                Swal.fire({
                    icon: 'warning',
                    title: 'Warning',
                    text: 'Farmer mobile number is not associated with the FPO',
                    didOpen: () => {
                        const swalElement = document.querySelector('.swal2-container');
                        if (swalElement) {
                            swalElement.style.zIndex = 1500;
                        }
                    }
                });
            }
        } catch (error) {
            setFarmerAssociated(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error?.response?.data?.message || 'Something went wrong',
                didOpen: () => {
                    const swalElement = document.querySelector('.swal2-container');
                    if (swalElement) {
                        swalElement.style.zIndex = 1500;
                    }
                }
            });
        }
    };


    return (
        <Dialog
            open={openAddDialog}
            onClose={() => setOpenAddDialog(false)}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '95%',
                    height: '100%',
                    maxHeight: '85%',
                    margin: 'auto',
                },
            }}
        >
            <DialogTitle sx={{ marginBottom: 2 }} className='bg-[#00B251] text-white'>
                Add Sales
            </DialogTitle>

            <DialogContent className='flex' sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {salesData.map((row, index) => (
                    <div key={index} className="w-full p-4 bg-gray-50 rounded-lg shadow-md relative">
                        <div className="flex justify-between items-center pb-2 border-b mb-4">
                            <Typography variant="h6" className="text-xl font-medium">
                                Sales - {index + 1}
                            </Typography>
                            {salesData.length > 1 && (
                                <IconButton
                                    onClick={() => handleDeleteRow(index)}
                                    color="error"
                                    className="absolute"
                                    sx={{ top: 10, right: 10 }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
                            {index === 0 && (
                                <div className="flex items-center gap-2">
                                    <TextField
                                        label="Mobile No"
                                        fullWidth
                                        margin="dense"
                                        value={row.mobile_no}
                                        onChange={(e) => {
                                            const updatedSalesData = [...salesData];
                                            updatedSalesData[index].mobile_no = e.target.value;
                                            setSalesData(updatedSalesData);
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleVerifyMobileNumber(index)}
                                    >
                                        Verify
                                    </Button>
                                </div>
                            )}   
                            <TextField
                                label="Name"
                                fullWidth
                                margin="dense"
                                value={row.name}
                                onChange={(e) => {
                                    const updatedSalesData = [...salesData];
                                    updatedSalesData[index].name = e.target.value;
                                    setSalesData(updatedSalesData);
                                }}
                            />
                            <TextField
                                label="Address"
                                fullWidth
                                margin="dense"
                                value={row.address}
                                onChange={(e) => {
                                    const updatedSalesData = [...salesData];
                                    updatedSalesData[index].address = e.target.value;
                                    setSalesData(updatedSalesData);
                                }}
                            />
                            <TextField
                                label="Product ID"
                                fullWidth
                                margin="dense"
                                type="number"
                                value={row.product_id}
                                onChange={(e) => {
                                    const updatedSalesData = [...salesData];
                                    updatedSalesData[index].product_id = e.target.value;
                                    setSalesData(updatedSalesData);
                                }}
                            />
                            <TextField
                                label="Variant ID"
                                fullWidth
                                margin="dense"
                                type="number"
                                value={row.variant_id}
                                onChange={(e) => {
                                    const updatedSalesData = [...salesData];
                                    updatedSalesData[index].variant_id = e.target.value;
                                    setSalesData(updatedSalesData);
                                }}
                            />
                            <TextField
                                label="Quantity"
                                fullWidth
                                margin="dense"
                                type="number"
                                value={row.quantity}
                                onChange={(e) => {
                                    const updatedSalesData = [...salesData];
                                    updatedSalesData[index].quantity = e.target.value;
                                    setSalesData(updatedSalesData);
                                }}
                            />

                            {/* Show discount field only if farmer is associated */}
                            {farmerAssociated && (
                                <TextField
                                    label="Discount Name"
                                    fullWidth
                                    margin="dense"
                                    value={row.discount_name || ''}
                                    onChange={(e) => {
                                        const updatedSalesData = [...salesData];
                                        updatedSalesData[index].discount_name = e.target.value;
                                        setSalesData(updatedSalesData);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                ))}

                {/* Add Row Button */}
                <IconButton onClick={handleAddRow} sx={{ marginTop: 2, alignSelf: 'flex-start' }} color="primary">
                    <AddIcon />
                </IconButton>
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button sx={{ color: '#00B251' }} onClick={() => setOpenAddDialog(false)}>
                    Cancel
                </Button>
                <Button sx={{ color: '#00B251' }} onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddSales;
