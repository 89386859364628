import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Autocomplete, Button, FormControl, InputLabel, Select, MenuItem, Typography, IconButton, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import Swal from 'sweetalert2';
import { GetAllVendors, RequestPurchasetoVendor } from '../../Api_url'

const AddPurchaseDialog = ({ openDialog, setOpenDialog, fetchPurchaseRequestData }) => {
    const [products, setProducts] = useState([]);
    const [brands, setBrands] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([{
        selectedProduct: null,
        quantity: '',
        measurement: '',
        variant: '',
        category: '',
        selectedBrand: null,
        selectedVendor: null,
        quantityError: ''
    }]);
    const [lastPage, setLastPage] = useState(false);  // To track if we've reached the last page of products
    const listInnerRef = useRef();
    const scrollPositionRef = useRef(0); // To track scroll position

    // Fetch Products, Brands, and Vendors from API
    const fetchProducts = async (page, search) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://apis.agrisarathi.com/fposupplier/GetallData?filter_type=products&page=${page}&search=${search}`);
            const newProducts = response?.data?.results?.data;

            if (newProducts?.length > 0) {
                setProducts((prevProducts) => {
                    // Filter out products that are already in the array
                    const uniqueNewProducts = newProducts.filter(
                        (newProduct) => !prevProducts.some((existingProduct) => existingProduct?.product_id === newProduct?.product_id)
                    );
                    return [...prevProducts, ...uniqueNewProducts];
                });
            }

            setLoading(false);
        } catch (error) {
            console.error('Error fetching products:', error);
            setLoading(false);
        }
    };




    // Fetch Vendors
    const fetchVendors = async () => {
        try {
            const response = await axios.get('https://apis.agrisarathi.com/vendor/GetAllVendors');
            setVendors(response?.data?.vendors);
        } catch (error) {
            console.error('Error fetching vendors:', error);
        }
    };

    // Handle scroll event to detect when user reaches the bottom
    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight && !loading && !lastPage) {
                // Save scroll position before new data is fetched
                scrollPositionRef.current = scrollTop;

                // Fetch new products
                setPage(prevPage => {
                    const nextPage = prevPage + 1;
                    fetchProducts(nextPage);
                    return nextPage;
                });
            }
        }
    };

    // Handle product selection change
    const handleProductChange = (index, value) => {
        const newRows = [...rows];
        newRows[index].selectedProduct = value;

        if (value) {
            // Update the measurement, variant, and category for the selected product
            newRows[index].measurement = value?.measurement?.description;
            newRows[index].variant = value?.variants ? value?.variants[0]?.variant_name : '';
            newRows[index].category = value?.category?.name;

            // Extract the brand data for the selected product
            const productBrands = value?.brands || [];

            // If there are brands for the selected product, update the brand field
            newRows[index].selectedBrand = productBrands[0] || null; // Default to the first brand

            // Set brands for all rows, assuming you want the same brand options across all rows
            setBrands(productBrands);
        }

        setRows(newRows);
    };

    // Handle quantity change
    const handleQuantityChange = (index, value) => {
        const newRows = [...rows];
        newRows[index].quantity = value;
        if (value < 0) {
            newRows[index].quantityError = 'Quantity cannot be negative.';
        } else {
            newRows[index].quantityError = '';
        }
        setRows(newRows);
    };

    // Handle variant change
    const handleVariantChange = (index, value) => {
        const newRows = [...rows];
        newRows[index].variant = value;
        setRows(newRows);
    };

    // Handle brand change
    const handleBrandChange = (index, value) => {
        const newRows = [...rows];
        newRows[index].selectedBrand = value;
        setRows(newRows);
    };


    // Handle vendor change
    const handleChange = (index, value) => {
        const newRows = [...rows];
        newRows[index].selectedVendor = value;
        setRows(newRows);
    };

    // Handle add row
    const handleAddRow = () => {
        setRows([...rows, {
            selectedProduct: null,
            quantity: '',
            measurement: '',
            variant: '',
            category: '',
            selectedBrand: null,
            selectedVendor: null,
            quantityError: ''
        }]);
    };

    // Handle delete row
    const handleDeleteRow = (index) => {
        const newRows = rows?.filter((_, rowIndex) => rowIndex !== index);
        setRows(newRows);
    };

    // Handle form submit


    const handleSubmit = async () => {
        // Check for invalid rows
        const invalidRow = rows.some(row =>
            !row?.selectedProduct ||
            !row?.selectedProduct.product_id ||
            !row?.quantity ||
            Number(row?.quantity) <= 0 ||
            !row?.selectedProduct?.measurement?.measurement_id ||
            !row?.variant ||
            !row?.category ||
            !row?.selectedBrand ||
            !row?.selectedVendor
        );

        if (invalidRow) {
            // Show error message with Swal
            Swal.fire({
                title: 'Error!',
                text: 'Please fill all fields.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return;
        }

        const vendor_id = rows[0].selectedVendor?.id;

        const products = rows.map(row => ({
            product_id: row?.selectedProduct?.product_id,
            category_id: row?.selectedProduct?.category?.category_id,
            quantity: row?.quantity,
            measurement_id: row?.selectedProduct?.measurement?.measurement_id,
            brand_id: row?.selectedBrand?.brand_id,
            variant_id: row?.selectedProduct?.variants?.find(variant => variant?.variant_name === row?.variant)?.variant_id,
        }));

        const data = {
            vendor_id,
            products: JSON.stringify(products)
        };

        const access_token = localStorage.getItem('access_token');

        if (!access_token) {
            // Show error message with Swal if token is missing
            Swal.fire({
                title: 'Error!',
                text: 'Access token is missing. Please log in again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return;
        }

        try {
            const response = await axios.post(RequestPurchasetoVendor, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`,
                },
            });

            // Show success message with Swal
            Swal.fire({
                title: 'Success!',
                text: 'Your request has been successfully submitted.',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false
            });

            setOpenDialog(false);
            fetchPurchaseRequestData();
        } catch (error) {
            // Show error message with Swal on failure
            Swal.fire({
                title: 'Error!',
                text: 'Failed to submit the data. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            setOpenDialog(false);
        }
    };


    useEffect(() => {

        fetchVendors();
        fetchProducts(page, searchQuery);

    }, [page, searchQuery]);

    useEffect(() => {
        // After new data is loaded, scroll back to the previous position
        if (listInnerRef.current) {
            listInnerRef.current.scrollTop = scrollPositionRef.current;
        }
    }, [products]);

    return (
        <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '95%',
                    height: '100%',
                    maxHeight: '85%',
                    margin: 'auto',
                },
            }}
        >
            <DialogTitle sx={{ marginBottom: 2 }} className='bg-[#00B251] text-white'>
                Add Purchase
            </DialogTitle>
            <DialogContent className='flex' sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {rows.map((row, index) => (
                    <div key={index} className="w-full flex flex-wrap gap-4 relative">
                        <div className="w-full p-4 bg-gray-50 rounded-lg shadow-md relative">
                            {/* Delete Button */}
                            {rows.length > 1 && (
                                <IconButton
                                    onClick={() => handleDeleteRow(index)}
                                    color="error"
                                    className="absolute"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}

                            {/* Product Title */}
                            <div className="flex justify-between items-center pb-2 border-b mb-4">
                                <Typography variant="h6" className="text-xl font-medium">
                                    {`Product - ${index + 1}`}
                                </Typography>
                            </div>

                            {/* Form Fields */}
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
                                {/* Product Select */}
                                <Autocomplete
                                    className="mt-2"
                                    fullWidth
                                    options={products}  // Check that products array has data
                                    getOptionLabel={(option) => option?.product_name || 'No product name'}
                                    value={row?.selectedProduct}
                                    onInputChange={(e, newInputValue) => {
                                        setSearchQuery(newInputValue);  // Update the search query with user input
                                        setPage(1);  // Reset to first page
                                        console.log('Search query:', newInputValue); // Log to check the value
                                        fetchProducts(1, newInputValue);  // Trigger fetch with new search term
                                    }}

                                    onChange={(e, value) => handleProductChange(index, value)}
                                    renderInput={(params) => (
                                        <>
                                            <TextField {...params} label="Product" fullWidth sx={{ flex: 1 }} />
                                            {loading && <CircularProgress size={24} sx={{ position: 'absolute', right: 10, top: '50%' }} />}
                                        </>
                                    )}
                                    ListboxComponent={(props) => (
                                        <div
                                            {...props}
                                            onScroll={onScroll}
                                            ref={listInnerRef}
                                            style={{ maxHeight: 300, overflowY: 'auto' }}
                                        >
                                            {props?.children}
                                        </div>
                                    )}
                                />




                                {/* Quantity */}
                                <TextField
                                    label="Quantity"
                                    value={row.quantity}
                                    onChange={(e) => handleQuantityChange(index, e.target.value)}
                                    margin="dense"
                                    type="number"
                                    fullWidth
                                />
                                {row.quantityError && (
                                    <Typography className="text-red-500 text-sm">{row.quantityError}</Typography>
                                )}

                                {/* Measurement */}
                                <TextField
                                    label="Measurement"
                                    value={row.measurement}
                                    margin="dense"
                                    fullWidth
                                />

                                {/* Variant Select */}
                                <FormControl fullWidth margin="dense">
                                    <InputLabel>Variant</InputLabel>
                                    <Select
                                        value={row.variant}
                                        onChange={(e) => handleVariantChange(index, e.target.value)}
                                        label="Variant"
                                        disabled={!row.selectedProduct}
                                    >
                                        {row.selectedProduct?.variants?.map((variantOption) => (
                                            <MenuItem key={variantOption.variant_id} value={variantOption.variant_name}>
                                                {variantOption.variant_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {/* Category */}
                                <TextField
                                    label="Category"
                                    value={row.category}
                                    margin="dense"
                                    fullWidth
                                />

                                {/* Brand Select */}
                                <FormControl fullWidth margin="dense">
                                    <InputLabel>Brand</InputLabel>
                                    <Select
                                        value={row.selectedBrand?.brand_name || ''}
                                        onChange={(e) => handleBrandChange(index, e.target.value)}
                                        label="Brand"
                                        disabled={!row.selectedProduct}
                                    >
                                        {row.selectedProduct?.brands?.map((brandOption) => (
                                            <MenuItem key={brandOption.brand_id} value={brandOption.brand_name}>
                                                {brandOption.brand_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <Autocomplete
                                    className='mt-2'
                                    fullWidth
                                    options={vendors}
                                    getOptionLabel={(option) => option?.name}
                                    value={row?.selectedVendor}
                                    onChange={(e, value) => handleChange(index, value)}
                                    renderInput={(params) => <TextField {...params} label="Vendor" />}
                                />

                            </div>
                        </div>
                    </div>

                ))}


                <IconButton onClick={handleAddRow} sx={{ marginTop: 2, alignSelf: 'flex-start' }} color="primary">
                    <AddIcon />
                </IconButton>
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button sx={{ color: '#00B251' }} onClick={() => setOpenDialog(false)}>
                    Cancel
                </Button>
                <Button sx={{ color: '#00B251' }} onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddPurchaseDialog;
