import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import { Button, TableContainer, Paper, TablePagination, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Cloud, CloudOff } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { AddGetSalesbyFPO } from '../../Api_url';
import AddSales from './AddSales';

const SalesHome = () => {
  const [salesData, setSalesData] = useState([]);
  const [filter, setFilter] = useState('offline');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedSale, setSelectedSale] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);


  const [dialogPage, setDialogPage] = useState(0);
  const [dialogRowsPerPage, setDialogRowsPerPage] = useState(5);


  const fetchData = async () => {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        Swal.fire({
          icon: 'error',
          title: 'Authentication failed!',
          text: 'No access token found. Please login again.',
        });
        return;
      }

      const response = await axios.get(AddGetSalesbyFPO, {
        params: { filter_type: filter },
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response?.data?.message === 'Sales fetched successfully') {
        setSalesData(response?.data?.sales);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Failed to fetch sales data',
          text: 'There was an issue fetching the sales data. Please try again.',
        });
      }
    } catch (error) {
      console.error('Error fetching sales data:', error);
      Swal.fire({
        icon: 'error',
        title: 'Network Error',
        text: 'There was an error connecting to the server. Please check your internet connection.',
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter]);

  const columns = useMemo(() => [
    { header: 'SNo.', accessorFn: (row, index) => index + 1, size: 50 },
    { header: 'Sale ID', accessorKey: 'sale_id', size: 50 },
    { header: 'Customer', accessorKey: 'customer', size: 50 },
    {
      header: 'Created At',
      accessorKey: 'created_at',
      Cell: ({ row }) => <Typography>{new Date(row?.original?.created_at).toLocaleString()}</Typography>,
      size: 50,
    },
    { header: 'Final Price', accessorKey: 'final_price', size: 50 },
    {
      header: 'Items',
      accessorFn: (row) => row?.items,
      Cell: ({ row }) => (
        <Button
          variant="contained"
          onClick={() => handleOpenDialog(row?.original)}
          color="primary"
        >
          Click to View Items
        </Button>
      ),
      size: 180,
    },
  ], [filter]);

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  const handleOpenDialog = (sale) => {
    setSelectedSale(sale);  // Set selected sale data
    setOpenDialog(true);    // Open the dialog
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);  // Close the dialog
    setSelectedSale(null);  // Clear the selected sale
  };

  const handleDialogPageChange = (event, newPage) => setDialogPage(newPage);
  const handleDialogRowsPerPageChange = (event) => {
    setDialogRowsPerPage(parseInt(event?.target?.value, 10));
    setDialogPage(0);
  };

  const paginatedData = salesData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const totalCount = salesData?.length;

  const dialogPaginatedItems = selectedSale?.items?.slice(dialogPage * dialogRowsPerPage, dialogPage * dialogRowsPerPage + dialogRowsPerPage);
  const dialogTotalCount = selectedSale?.items?.length;

  return (
    <div className="p-6">
      <div className="flex justify-center gap-8 mb-6">
        <button
          className="flex items-center px-6 py-2 bg-blue-600 text-white font-semibold rounded-full shadow-lg hover:bg-blue-700 transition-all duration-300"
          onClick={() => setFilter('online')}
        >
          <Cloud className="mr-2" />
          Online
        </button>
        <button
          className="flex items-center px-6 py-2 bg-green-600 text-white font-semibold rounded-full shadow-lg hover:bg-green-700 transition-all duration-300"
          onClick={() => setFilter('offline')}
        >
          <CloudOff className="mr-2" />
          Offline
        </button>
      </div>

      <div className="text-center mb-6">
        <h2 className="text-xl font-semibold">
          Sales: <span className="font-bold text-blue-600">{filter?.charAt(0)?.toUpperCase() + filter?.slice(1)}</span>
        </h2>
      </div>

      {/* <div className="flex flex-col md:flex-row gap-4 w-1/2">

        <Button
          variant="contained"
          onClick={() => setOpenAddDialog(true)}
          className="w-full md:w-1/2 sm:w-auto py-3 mt-4 sm:mt-0"
        >
          Add Sales
        </Button>
      </div> */}

      <div style={{ maxWidth: '1180px', overflowY: 'auto', padding: 10 }}>
        <TableContainer component={Paper} className="overflow-x-auto mt-6 relative">
          <MaterialReactTable
            columns={columns}
            data={paginatedData}
            enablePagination={false}
          />

          <div className="absolute bottom-0 left-0 right-0 z-10 bg-white">
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </TableContainer>
      </div>

      {openAddDialog && <AddSales openAddDialog={openAddDialog} setOpenAddDialog={setOpenAddDialog} />}

      {/* Dialog for displaying items */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
        <DialogTitle sx={{ marginBottom: 2 }} className='bg-[#00B251] text-white'>Items for Sale: {selectedSale?.sale_id}</DialogTitle>
        <DialogContent>
          <MaterialReactTable
            columns={[
              { header: 'Product', accessorKey: 'product' },
              { header: 'Variant', accessorKey: 'variant' },
              { header: 'Quantity', accessorKey: 'quantity' },
              { header: 'Price', accessorKey: 'price' },
            ]}
            data={dialogPaginatedItems || []}
            enablePagination={false}
          />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dialogTotalCount}
            rowsPerPage={dialogRowsPerPage}
            page={dialogPage}
            onPageChange={handleDialogPageChange}
            onRowsPerPageChange={handleDialogRowsPerPageChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SalesHome;
