import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import { Button, Typography, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Cloud, CloudOff } from '@mui/icons-material';
import Swal from 'sweetalert2';
import AddInventory from './AddInventory';
import { Edit } from '@mui/icons-material';
import { GetDelUpdateInventory, CreateGetOrderReturns, UserProfileView, AddInventoryToMarketplace } from '../../Api_url';
import { TableContainer, Paper, TablePagination } from '@mui/material';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';

const InventoryHome = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [filter, setFilter] = useState('offline');
  const [openDialog, setOpenDialog] = useState(false);
  const [currentInventoryId, setCurrentInventoryId] = useState(null);
  const [currentOnlinePrice, setCurrentOnlinePrice] = useState('');
  const [currentOfflinePrice, setCurrentOfflinePrice] = useState('');
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openReturnDialog, setOpenReturnDialog] = useState(false);
  const [quantity, setQuantity] = useState('');
  const [reason, setReason] = useState('');
  const [supplierShop, setSupplierShop] = useState(null);  // Add this line
  const [onlineDialog, setOnlineDialog] = useState(false); // Change from openDialog to onlineDialog


  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  const paginatedData = inventoryData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const totalCount = inventoryData?.length;

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) return;
      const response = await axios.get(GetDelUpdateInventory, {
        params: { filter_type: filter },
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setInventoryData(response?.data?.purchase_orders);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter]);

  const handleAddToOnlineMarket = async (inventory_id) => {

    try {
      // Fetch supplier shop info
      const token = localStorage.getItem('access_token');
      if (!token) return;

      const userProfileResponse = await axios.get(UserProfileView, {
        headers: { 'Authorization': `Bearer ${token}` },
      });

      const shopId = userProfileResponse?.data?.data?.shop_details[0]?.shop_id;
      if (!shopId) {
        Swal.fire({
          icon: 'error',
          title: 'Shop not found!',
          text: 'No supplier shop found, unable to add to marketplace.',
        });
        return;
      }
      setSupplierShop(shopId); // Set the supplier shop ID

      setCurrentInventoryId(inventory_id); // Set the inventory ID
      setOnlineDialog(true);

    } catch (error) {
      console.error('Error fetching supplier shop:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed to fetch shop details!',
        text: 'Unable to fetch supplier shop. Please try again later.',
      });
    }
  };

  const handleSubmitAddToOnline = async () => {

    const parsedQuantity = parseInt(quantity, 10);

    if (isNaN(parsedQuantity) || parsedQuantity <= 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Please provide a valid quantity!',
        text: 'Quantity must be a positive number.',
        showConfirmButton: true,
      });
      return;
    }

    try {
      const token = localStorage.getItem('access_token');
      if (!token) return;

      const response = await axios.post(
        AddInventoryToMarketplace,
        {
          inventory_products: [
            {
              inventory_id: currentInventoryId,
              quantity: parsedQuantity,
              supplier_shop: supplierShop,
            },
          ],
        },
        {
          headers: { 'Authorization': `Bearer ${token}` },
        }
      );

      if (response?.data?.message === 'Products added to marketplace successfully') {
        setOnlineDialog(false);
        Swal.fire({
          icon: 'success',
          title: 'Added to Online Market!',
          text: 'Inventory has been successfully added to the marketplace.',
          didOpen: () => {
            const swalElement = document.querySelector('.swal2-container');
            if (swalElement) {
              swalElement.style.zIndex = 1500;
            }
          }
        });


        setQuantity(''); // Clear quantity field
        fetchData(); // Refresh inventory data
      } else {
        setOnlineDialog(false);
        Swal.fire({
          icon: 'error',
          title: 'Failed to add inventory!',
          text: 'Something went wrong while adding to the online market. Please try again.',
          didOpen: () => {
            const swalElement = document.querySelector('.swal2-container');
            if (swalElement) {
              swalElement.style.zIndex = 1500;
            }
          }
        });
      }
    } catch (error) {
      setOnlineDialog(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong while adding to the online market. Please try again!',
        didOpen: () => {
          const swalElement = document.querySelector('.swal2-container');
          if (swalElement) {
            swalElement.style.zIndex = 1500;
          }
        }
      });
    }
  };

  const handlePriceChange = (e) => {
    if (filter === 'online') {
      setCurrentOnlinePrice(e?.target?.value);
    } else {
      if (e.target.name === 'online_price') {
        setCurrentOnlinePrice(e?.target?.value);
      } else {
        setCurrentOfflinePrice(e?.target?.value);
      }
    }
  };

  const savePriceChange = async () => {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) return;

      const priceToUpdate = filter === 'online'
        ? { online_price: currentOnlinePrice }
        : { online_price: currentOnlinePrice, offline_price: currentOfflinePrice };

      await axios.put(GetDelUpdateInventory, {
        inventory_id: currentInventoryId,
        ...priceToUpdate,
      }, {
        headers: { 'Authorization': `Bearer ${token}` },
      });

      fetchData();
      Swal.fire({
        icon: 'success',
        title: 'Price updated successfully!',
        showConfirmButton: false,
        timer: 1500,
      });
      setOpenDialog(false);
    } catch (error) {
      setOpenDialog(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong while updating the price. Please try again!',
        didOpen: () => {
          const swalElement = document.querySelector('.swal2-container');
          if (swalElement) {
            swalElement.style.zIndex = 1500;
          }
        }
      });
    }
  };

  const handleEditClick = (inventory_id, online_price, offline_price) => {
    setCurrentInventoryId(inventory_id);
    setCurrentOnlinePrice(online_price);
    setCurrentOfflinePrice(offline_price);
    setOpenDialog(true);
  };

  const handleReturnClick = (inventory_id) => {
    setCurrentInventoryId(inventory_id);
    setOpenReturnDialog(true);
  };

  const submitReturnRequest = async () => {
    if (!quantity || !reason) {
      Swal.fire({
        icon: 'warning',
        title: 'Please fill in both fields!',
        text: 'Both quantity and reason are required to submit the return request.',
        showConfirmButton: true,
        didOpen: () => {
          const swalElement = document.querySelector('.swal2-container');
          if (swalElement) {
            swalElement.style.zIndex = 1500;
          }
        }
      });
      return; // Don't proceed with the API call if fields are not filled
    }

    try {
      const token = localStorage.getItem('access_token');
      if (!token) return;

      const response = await axios.post(CreateGetOrderReturns, {
        inventory_id: currentInventoryId,
        quantity: quantity,
        reason: reason
      }, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setOpenReturnDialog(false);
      Swal.fire({
        icon: 'success',
        title: 'Return request submitted successfully!',
        showConfirmButton: false,
        timer: 1500,
      });

      setQuantity('');
      setReason('');
      fetchData();
    } catch (error) {
      setOpenReturnDialog(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong while submitting the return request. Please try again!',
        didOpen: () => {
          const swalElement = document.querySelector('.swal2-container');
          if (swalElement) {
            swalElement.style.zIndex = 1500;
          }
        }
      });
    }
  };


  const columns = useMemo(() => {
    const baseColumns = [
      { header: 'SNo.', accessorFn: (row, index) => index + 1, size: 50 },

      // Actions column, which will be conditionally rendered based on the filter
      {
        header: 'Actions',
        accessorFn: (row) => row?.inventory_id,
        Cell: ({ row }) => {
          const { online_price, offline_price, is_return } = row?.original;
          return (
            filter === 'offline' && (
              <div className="flex">
                {/* Edit and Return buttons */}
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Edit />}
                  onClick={() => handleEditClick(row?.original?.inventory_id, online_price, offline_price)}
                >
                  Edit
                </Button>

                {is_return && (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AssignmentReturnIcon />}
                    onClick={() => handleReturnClick(row?.original?.inventory_id)}
                    sx={{ marginLeft: 1 }}
                  >
                    Return
                  </Button>
                )}
              </div>
            )
          );
        },
      },

      // Separate section for "Add to Online Market" button
      {
        header: 'Add to Online Market',
        accessorFn: (row) => row?.inventory_id,
        Cell: ({ row }) => {
          return (
            filter === 'offline' && (
              <div className="flex justify-center">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleAddToOnlineMarket(row?.original?.inventory_id)} // Add to online market button
                  sx={{ marginLeft: 1 }}
                >
                  Add to Online Market
                </Button>
              </div>
            )
          );
        },
      },

      // Other columns (no changes needed here)
      { header: 'Inventory ID', accessorKey: 'inventory_id', size: 50 },
      { header: 'Product Name', accessorKey: 'product_name', size: 50 },
      { header: 'Variant', accessorKey: 'variant_name', size: 50 },
      { header: 'Stock', accessorKey: 'stock', size: 50 },
      { header: 'Category', accessorKey: 'category', size: 50 },
      { header: 'Brand', accessorKey: 'brand', size: 50 },
    ];


    if (filter === 'online') {
      baseColumns.push({
        header: 'Online Price',
        accessorKey: 'online_price',
        Cell: ({ row }) => <Typography>{row?.original?.online_price}</Typography>,
        size: 50,
      });
    } else if (filter === 'offline') {
      baseColumns.push(
        {
          header: 'Offline Price',
          accessorKey: 'offline_price',
          Cell: ({ row }) => <Typography>{row?.original?.offline_price}</Typography>,
          size: 50,
        },
        {
          header: 'Online Price',
          accessorKey: 'online_price',
          Cell: ({ row }) => <Typography>{row?.original?.online_price}</Typography>,
          size: 50,
        }
      );
    }

    return baseColumns;
  }, [filter]);

  return (
    <div className="p-6">
      <div className="flex justify-center gap-8 mb-6">
        <button
          className="flex items-center px-6 py-2 bg-blue-600 text-white font-semibold rounded-full shadow-lg hover:bg-blue-700 transition-all duration-300"
          onClick={() => setFilter('online')}
        >
          <Cloud className="mr-2" />
          Online
        </button>
        <button
          className="flex items-center px-6 py-2 bg-green-600 text-white font-semibold rounded-full shadow-lg hover:bg-green-700 transition-all duration-300"
          onClick={() => setFilter('offline')}
        >
          <CloudOff className="mr-2" />
          Offline
        </button>
      </div>

      <div className="text-center mb-6">
        <h2 className="text-xl font-semibold">
          Inventory: <span className="font-bold text-blue-600">{filter?.charAt(0)?.toUpperCase() + filter?.slice(1)}</span>
        </h2>
      </div>

      <div className="flex flex-col md:flex-row gap-4 w-1/2">

        <Button
          variant="contained"
          onClick={() => setOpenAddDialog(true)}
          className="w-full md:w-1/2 sm:w-auto py-3 mt-4 sm:mt-0"
        >
          Add Inventory To Offline
        </Button>
      </div>

      <div style={{ maxWidth: '1180px', overflowY: 'auto', padding: 10 }}>
        <TableContainer component={Paper} className="overflow-x-auto mt-6 relative">
          <MaterialReactTable
            columns={columns}
            data={paginatedData}
            enablePagination={false}
          />

          <div className="absolute bottom-0 left-0 right-0 z-10 bg-white">
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </TableContainer>
      </div>

      {openAddDialog && <AddInventory openAddDialog={openAddDialog} setOpenAddDialog={setOpenAddDialog} />}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle sx={{ marginBottom: 2 }} className='bg-[#00B251] text-white'>
          Edit Price
        </DialogTitle>
        <DialogContent>
          {filter === 'online' ? (
            <TextField
              label="Online Price"
              name="online_price"
              value={currentOnlinePrice}
              onChange={handlePriceChange}
              fullWidth
              type="number"
              variant="outlined"
              margin="normal"
            />
          ) : (
            <>
              <TextField
                label="Offline Price"
                name="offline_price"
                value={currentOfflinePrice}
                onChange={handlePriceChange}
                fullWidth
                type="number"
                variant="outlined"
                margin="normal"
              />
              <TextField
                label="Online Price"
                name="online_price"
                value={currentOnlinePrice}
                onChange={handlePriceChange}
                fullWidth
                type="number"
                variant="outlined"
                margin="normal"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: '#00B251' }} onClick={() => setOpenDialog(false)} >
            Cancel
          </Button>
          <Button sx={{ color: '#00B251' }} onClick={savePriceChange} >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openReturnDialog} onClose={() => setOpenReturnDialog(false)}>
        <DialogTitle sx={{ marginBottom: 2 }} className='bg-[#00B251] text-white'>
          Return Item
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            fullWidth
            type="number"
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="Reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: '#00B251' }} onClick={() => setOpenReturnDialog(false)}>
            Cancel
          </Button>
          <Button sx={{ color: '#00B251' }} onClick={submitReturnRequest}>
            Submit Return
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={onlineDialog} onClose={() => setOnlineDialog(false)}>
        <DialogTitle sx={{ marginBottom: 2 }} className="bg-[#00B251] text-white">
          Add to Online Market
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            fullWidth
            type="number"
            variant="outlined"
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: '#00B251' }} onClick={() => setOnlineDialog(false)}>
            Cancel
          </Button>
          <Button sx={{ color: '#00B251' }} onClick={handleSubmitAddToOnline}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>


    </div>
  );
};

export default InventoryHome;
